<template>
  <ActivationLoading v-if="loadingSsoStatus && !isMicrosoft" />

  <ActivationError
    v-else-if="errorState"
    :error="activationError"
    @reset="resetError(true)"
  />

  <v-container v-else fluid class="fill-height">
    <v-card
      color="transparent"
      class="elevation-0 ma-auto"
      width="1400"
      :max-width="$vuetify.breakpoint.mobile ? '95%' : '70%'"
    >
      <ActivationProgressBar />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            absolute
            left
            color="white"
            elevation="0"
            class="mt-3"
            v-bind="attrs"
            v-on="on"
            @click="cancel"
          >
            <v-icon v-text="'mdi-close'" color="secondary lighten-2" />
          </v-btn>
        </template>
        <span>{{ $ml.get("cancel") }}</span>
      </v-tooltip>
      <v-tooltip
        left
        v-if="currentActivationStep === 4 && !showDomainSelection"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-large
            fab
            absolute
            text
            right
            elevation="0"
            color="accent"
            class="text-none no-background-hover text-body-2 text-md-body-1 font-weight-bold px-10 mr-4"
            v-bind="attrs"
            v-on="on"
            @click="cancel"
          >
            {{ $ml.get("activate_later") }}
          </v-btn>
        </template>
        <span>{{ $ml.get("activate_later_description") }}</span>
      </v-tooltip>

      <v-window v-model="activationStep" touchless style="width: 100%">
        <!-- FIRST STEP: GET STARTED -->
        <v-window-item :value="1">
          <SelectDomainStep
            v-if="showDomainSelection && isMicrosoft"
            @previous="
              activationStep = 1;
              showDomainSelection = false;
            "
            @next="activationStep = 2"
          />
          <GetStarted
            v-else
            @started="
              isMicrosoft ? (showDomainSelection = true) : (activationStep = 2)
            "
          />
        </v-window-item>

        <!-- SECOND STEP: USERS SHIFT -->
        <v-window-item :value="2">
          <UsersAccessInstructionsStep
            @previous="activationStep = 1"
            @skip="activationStep = 3"
          />
        </v-window-item>

        <v-window-item :value="3">
          <SetTemporaryPasswordStep
            v-if="!changedActivationPassword"
            @previous="updatePasswordStep(false, 2)"
          />
          <PasswordChangedStep
            v-else
            @previous="updatePasswordStep(false, 3)"
            @next="activationStep = 4"
          />
        </v-window-item>

        <v-window-item :value="4">
          <SelectLogoutOptionsStep
            :loading="loadingSsoStatus"
            @previous="activationStep = 3"
            @next="activate"
          />
        </v-window-item>
      </v-window>

      <div class="caption text-center mt-4 secondary--text">
        {{ $ml.get("illustrations_by") }}

        <a href="https://br.freepik.com/autor/stories" target="_blank">
          <span class="light-blue--text text--darken-4 font-weight-medium">
            Storyset
          </span>
        </a>
      </div>
    </v-card>

    <HeaderDialog
      id="default-password"
      :title="$ml.get('set_default_password')"
      :show="showEditDefaultPassword"
      width="600"
      :actionText="$ml.get('continue')"
      :disabledAction="hasPasswordError"
      :loading="loadingDefaultPassword"
      @close="closeEditPassword"
      closeText="cancel"
      @action="setDefaultPassword"
    >
      <template v-slot:body>
        <div class="mb-4">
          <span>
            {{ $ml.get("password_settings_subtitle") }}
          </span>
          <v-text-field
            v-if="showEditDefaultPassword"
            v-model="newDefaultPassword"
            :label="$ml.get('default_password')"
            class="mt-8"
            minLength="5"
            maxLength="30"
            style="max-height: 60px"
            :append-icon="showText ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showText ? 'password' : 'text'"
            :rules="companyRules"
            @update:error="hasPasswordError = $event"
            @click:append="showText = !showText"
            outlined
          />
        </div>
      </template>
    </HeaderDialog>

    <ActivationScheduleDialog
      v-if="controlInTrial"
      :activation-progress="percentage"
      @next="next = $event"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
import HeaderDialog from "@/components/base/HeaderDialog";
import ActivationScheduleDialog from "@/components/general/ActivationScheduleDialog.vue";
import ActivationProgressBar from "@/components/activation/ActivationProgressBar";
import GetStarted from "@/components/activation/init/GetStarted";
import UsersAccessInstructionsStep from "@/components/activation/first_step/UsersAccessInstructionsStep";
import SetTemporaryPasswordStep from "@/components/activation/second_step/SetTemporaryPasswordStep";
import PasswordChangedStep from "@/components/activation/second_step/PasswordChangedStep.vue";
import SelectLogoutOptionsStep from "@/components/activation/third_step/SelectLogoutOptionsStep";
import ActivationLoading from "@/components/activation/ActivationLoading.vue";
import ActivationError from "@/components/activation/ActivationError.vue";
import SelectDomainStep from "@/components/activation/third_step/SelectDomainStep";

export default {
  name: "ActivationSteps",

  components: {
    ActivationScheduleDialog,
    ActivationProgressBar,
    HeaderDialog,
    GetStarted,
    PasswordChangedStep,
    SelectLogoutOptionsStep,
    SetTemporaryPasswordStep,
    UsersAccessInstructionsStep,
    ActivationLoading,
    ActivationError,
    SelectDomainStep,
  },

  data() {
    return {
      activationError: {},
      errorState: false,

      showActivateControlDialog: false,
      steps: 0,
      completed: {},
      loadingSsoStatus: false,
      logoutUsers: "skip",
      showEditDefaultPassword: false,
      loadingDefaultPassword: false,
      showText: true,
      newDefaultPassword: "",
      rules: {
        equalPasswords: (value) =>
          value === this.userPassword.password ||
          this.$ml.get("passwords_do_not_match"),
        min_len: (v) =>
          v.length >= this.minimumSize ||
          `${this.$ml.get("min_len_label")} ${this.minimumSize}`,
        has_upper: (v) =>
          /[A-Z]/.test(v) || this.$ml.get("must_have_a_upper_letter"),
        has_lower: (v) =>
          /[a-z]/.test(v) || this.$ml.get("must_have_a_lower_letter"),
        has_number: (v) => /\d/.test(v) || this.$ml.get("must_have_a_number"),
        //eslint-disable-next-line
        has_symbol: (v) =>
          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(v) ||
          this.$ml.get("must_have_a_symbol"),
      },
      passwordStrengthRules: [],
      hasPasswordError: true,
      next: null,
      showDomainSelection: false,
    };
  },
  computed: {
    ...mapGetters([
      "totalUsersWithoutGroup",
      "groups",
      "hasControlPlan",
      "mainDomain",
      "users",
      "currentUser",
      "company",
      "passwordStrength",
      "isMicrosoft",
      "controlInTrial",
      "currentActivationStep",
      "changedActivationPassword",
      "domainsToActivate",
    ]),

    activationStep: {
      get() {
        return this.currentActivationStep;
      },
      set(newStep) {
        this.setActivationStep(newStep);
      },
    },

    minimumSize() {
      return this.passwordStrength.min_len;
    },

    companyRules() {
      return this.passwordStrengthRules;
    },

    allSteps() {
      return [
        {
          step: 1,
          title_key: "user_tour",
          description_key: "activationStep1Description",
          completed: this.completed[0],
          substeps: [
            {
              description_key: "without_group_alert",
              show: this.hasUsersWithoutGroup,
              to: "/users",
              tooltip_key: "go_to_users",
            },
            {
              description_key: "you_dont_have_groups",
              show: this.noGroups,
              to: "/groups",
              tooltip_key: "go_to_groups",
            },
          ].filter((item) => item.show),
          button_text: "set_up",
          to: "/groups",
        },
        {
          step: 2,
          title_key: "default_password",
          description_key: "activationStep2Description",
          completed: this.completed[1],
          button_text: "set_up",
          to: "/password-settings",
          action: "showEditDefaultPassword",
        },
        {
          step: 3,
          title_key: "activationStep3Title",
          description_key: "activationStep3Description",
          completed: this.completed[2],
        },
        {
          step: 4,
          title_key: "fourthStepTitle",
          tooltip: "active_control",
          completed: this.completed[3],
          substeps: [
            {
              description_key: "product_unavailable",
              show: !this.hasControlPlan,
              to: `${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan`,
              type: "link",
              tooltip_key: "go_to_suite_plans",
            },
          ].filter((item) => item.show),
          readonly: true,
          button_text: "activate",
          action: "activation",
        },
      ];
    },

    hasUsersWithoutGroup() {
      return this.totalUsersWithoutGroup > 0;
    },
    noGroups() {
      return this.groups.length === 0;
    },
    percentage() {
      return parseInt(
        (this.allSteps.filter((item) => item.completed === true).length * 100) /
          this.allSteps.length
      );
    },
  },

  watch: {
    activationStep() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    steps(_, oldValue) {
      if (oldValue !== 4) {
        this.showDomainSelection = true;
      }

      if (this.steps === 2) {
        var scrollDiv = document.getElementById("step-panel-4").offsetTop;
        window.scrollTo({ top: scrollDiv + 40, behavior: "smooth" });
      }
    },
    company() {
      if (
        this.activationStep != 1 &&
        this.isMicrosoft &&
        !this.domainsToActivate.length
      ) {
        this.activationStep = 1;
      }
    },
  },

  methods: {
    ...mapActions([
      "activateControl",
      "forceUsersLogout",
      "editDefaultPassword",
    ]),

    ...mapMutations([
      "setLoadingStatus",
      "updateCompanyNewSsoIsActive",
      "setChangedPasswordState",
      "setAskedGenerateRandomPassword",
      "setScheduleMeetingDialog",
      "setActivationStep",
      "setAskedSendLinkToChangePassword",
    ]),

    activate(logoutOption) {
      this.logoutUsers = logoutOption;

      this.activateConectaControlSSO();
    },

    cancel() {
      const analytics = getAnalytics();

      if (this.mainDomain) {
        logEvent(analytics, "cancel_control_activation", {
          main_domain: this.mainDomain,
          clicked_where:
            "Botão de fechar no canto superior direito da janela de ativação",
        });
      }

      this.$router.push({ name: "Home" });
    },

    async activateConectaControlSSO() {
      this.loadingSsoStatus = true;

      const payload = {
        domains: this.domainsToActivate,
      };

      await this.activateControl(payload)
        .then(() => {
          this.updateCompanyNewSsoIsActive(true);
          if (this.logoutUsers === "redirect") {
            return setTimeout(
              () => this.$router.push("/users?action=forceLogout"),
              1500
            );
          } else if (this.logoutUsers === "forceAll") {
            const payload = {
              users: this.users.reduce((emails, { is_active, email }) => {
                if (is_active && email !== this.currentUser.email) {
                  emails.push(email);
                }
                return emails;
              }, []),
            };
            this.forceUsersLogout(payload);
          }

          this.$router.push("/activation-completed");
        })
        .catch((error) => {
          this.errorState = true;
          this.activationError = error;
        })
        .finally(() => {
          this.loadingSsoStatus = false;
          this.showActivateControlDialog = false;
          this.setLoadingStatus(false);
        });
    },

    closeEditPassword() {
      this.showEditDefaultPassword = false;
      this.showText = false;
      this.newDefaultPassword = "";
      this.hasPasswordError = true;
    },

    setDefaultPassword() {
      this.loadingDefaultPassword = true;
      const payload = {
        password: this.newDefaultPassword,
      };
      this.editDefaultPassword(payload).finally(() => {
        this.loadingDefaultPassword = false;
        this.newDefaultPassword = "";
        this.showEditDefaultPassword = false;
        this.hasPasswordError = true;
      });
    },

    initRules() {
      const functionRulesApplied = [];

      for (let key in this.passwordStrength) {
        if (this.passwordStrength[key]) {
          functionRulesApplied.push(this.rules[key]);
        }
      }

      this.passwordStrengthRules = [...functionRulesApplied];
    },

    resetError(redirect = false) {
      this.errorState = false;
      this.activationError = {};

      if (redirect) {
        this.$router.push({ name: "Home" });
      }
    },

    updatePasswordStep(status, previous) {
      this.setChangedPasswordState(status);
      this.setAskedGenerateRandomPassword(status);
      this.setAskedSendLinkToChangePassword(status);

      this.activationStep = previous;
    },
  },

  async beforeMount() {
    this.initRules();
    if (localStorage.getItem("activationProgress")) {
      let savedSteps = JSON.parse(localStorage.getItem("activationProgress"));
      for (let i = 0; i < savedSteps.length; i++) {
        const step = savedSteps[i];
        this.completed = {
          ...this.completed,
          [i]: step.completed,
        };
      }
      this.steps =
        this.allSteps.findLastIndex((step) => step.completed === true) + 1;
    }
  },

  beforeRouteLeave(to, from, next) {
    const activationRoutes = ["Users", "Groups", "Group", "PasswordSettings"];

    const activationSteps =
      JSON.parse(localStorage.getItem("activationProgress")) || [];

    const hasStartedActivation =
      activationSteps.length > 0 &&
      activationSteps.some((steps) => steps.completed);

    this.next = next;

    if (
      hasStartedActivation &&
      !activationRoutes.includes(to.name) &&
      this.controlInTrial
    ) {
      this.setScheduleMeetingDialog(true);
    } else {
      this.next(true);
    }
  },
};
</script>

<style scoped lang="scss">
code {
  word-break: break-all;
}

.no-background-hover::before {
  background-color: transparent !important;
}
</style>
