<template>
  <v-card class="pb-0 mx-auto" width="1200">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          absolute
          left
          color="white"
          elevation="0"
          class="mt-3"
          v-bind="attrs"
          v-on="on"
          @click="$emit('reset')"
        >
          <v-icon v-text="'mdi-close'" color="secondary lighten-2" />
        </v-btn>
      </template>
      <span
        >{{ `${$ml.get("access")} ${$ml.get("homepage").toLowerCase()}` }}
      </span>
    </v-tooltip>

    <v-row justify="center" align="center" class="mb-8">
      <v-col cols="12" sm="12" md="6">
        <ActivationErrorIlustration />
      </v-col>

      <v-col cols="12" sm="12" md="6" class="px-5">
        <v-card
          flat
          class="d-flex flex-column align-center justify-center"
          style="width: 100%"
        >
          <v-card-title
            class="error--text text-h4 font-weight-bold text-center break-word text-wrap normal-line-height text-center mb-4"
          >
            {{ $ml.get("activation_error_disabled_plan_title") }}
          </v-card-title>

          <v-card-text
            v-html="$ml.get(error.code.toLowerCase())"
            class="normal-line-height text-body-2 text-md-body-1 text-center"
          />

          <v-btn
            v-if="canHirePlan"
            x-large
            color="primary"
            :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-8 mt-5`"
            :block="$vuetify.breakpoint.xs"
            @click="showPlans"
          >
            {{ $ml.get("know_the_plans") }}
            <v-icon v-text="'mdi-chevron-right'" right />
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ActivationErrorIlustration from "./ActivationErrorIlustration.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ActivationError",

  props: {
    error: { type: Object, required: true },
  },

  components: { ActivationErrorIlustration },

  computed: {
    ...mapGetters(["isMicrosoft"]),

    code() {
      return this.error ? this.error.code.toLowerCase() : "";
    },

    canHirePlan() {
      return ["disabled_or_not_found", "expired_trial"].includes(this.code);
    },
  },
  methods: {
    ...mapActions(["selfcheckoutRedirect"]),

    showPlans() {
      this.selfcheckoutRedirect();
      this.$emit("reset");
    },
  },
};
</script>
